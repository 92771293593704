<template>
    <div>
        <bloc-header2 page="Programme de professionnalisation" />
            <div class="containerAffichage">
                <bloc-professionnalisation  :sitePro=sitePro />
            </div>
        <bloc-footer/>
    </div>
</template>


<script>
import Professionnalisation from '@/components/pro/Professionnalisation.vue'

import Header2 from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Professionnalisation',

    components: {
        'bloc-professionnalisation': Professionnalisation,
        'bloc-header2': Header2,
        'bloc-footer': Footer,
    },
    props: {
        sitePro: {
            type: Boolean
        }
    },
    created() {
        if (this.sitePro) {
            this.$parent.sitePRO = true;
        }
    }
}
</script>


